<template>
  <div class="py-5 mb-5">
    <h1 class="text-primary">Forgot password</h1>
    <a-form
      layout="vertical"
      ref="formRef"
      :model="formState"
      :rules="formRules"
      @finish="handleRequestFinish"
    >
      <a-form-item label="E-mail" required has-feedback name="account_email">
        <a-input
          v-model:value="formState.account_email"
          placeholder="E-mail Address"
          type="email"
          size="large"
          autocomplete="forgot-pwd-master-email"
        />
      </a-form-item>
      <a-form-item>
        <div class="d-flex justify-content-center">
          <a-button :loading="loading" block type="primary" html-type="submit">
            Forgot password
          </a-button>
        </div>
      </a-form-item>
    </a-form>
  </div>
  <AlertModal
    v-model:visible="visible"
    :message="message"
    @close-alert-modal="visible = false"
  >
    <p class="text-center fw-light mt-3 mb-1" style="white-space: pre">
      {{ message }}
    </p>
    <p class="text-center mb-3 fs-3">
      <a-typography-text :type="type">
        <ExclamationCircleOutlined v-if="type === 'danger'" />
        <CheckCircleFilled v-else />
      </a-typography-text>
    </p>
  </AlertModal>
</template>
<script lang="ts">
import { defineComponent, ref, reactive, UnwrapRef, toRefs } from "vue";
import { useRequest } from "vue-request";
import { authService } from "@/_services";
import { ISignInPayload } from "@/_utils/types";
import AlertModal from "@/components/AlertModal.vue";
import { ExclamationCircleOutlined, CheckCircleFilled } from "@ant-design/icons-vue";
type FormState = Pick<ISignInPayload, "account_email">
interface IAlertModal {
  visible: boolean
  type: "success"|"danger"
  message: string
}
export default defineComponent({
  components: {
    AlertModal,
    ExclamationCircleOutlined,
    CheckCircleFilled
  },
  setup() {
    const { run: requestResetPwd, error } = useRequest(authService.resetPassword, { manual: true })
    const loading = ref<boolean>(false);
    const alertState = reactive<IAlertModal>({
      visible: false,
      message: "",
      type: "danger"
    })
    const formRef = ref();
    const formState: UnwrapRef<FormState> = reactive({
      account_email: ""
    });
    const formRules = {
      account_email: [
        {
          required: true,
          message: "Please input your e-mail address",
          trigger: "blur",
        },
        {
          type: "email",
          message: "Please input an e-mail address format",
          trigger: "blur",
        },
      ]
    };
    
    const handleRequestFinish = () => {
      loading.value = true
      const { account_email } = formState
      requestResetPwd( account_email ).then(
        result => {
          if (error.value) {
            alertState.type = 'danger'
            alertState.message = error.value.message
            //alertState.message = 'คุณไม่สามารถร้องขอรหัสผ่านใหม่ได้ เนื่องจาก บัญชีผู้ใช้งานของคุณไม่มีสิทธิ์ในการเข้าใช้งานระบบ'
            alertState.visible = true;
            return 
          }
          if (!result) {
            alertState.type = 'danger'
            alertState.message = 'ไม่พบอีเมลในระบบ กรุณาตรวจสอบอีกครั้ง';
            alertState.visible = true;
            return
          }
          alertState.type = 'success'
          alertState.message = 'กรุณาตรวจสอบอีเมล เพื่อรีเซ็ทรหัสผ่าน';
          alertState.visible = true;
        }
      ).finally(() => loading.value = false)
    };

    return {
      formRef,
      formRules,
      formState,
      handleRequestFinish,
      loading,
      ...toRefs(alertState)
    };
  }
})
</script>

