
import { defineComponent, ref, reactive, UnwrapRef, toRefs } from "vue";
import { useRequest } from "vue-request";
import { authService } from "@/_services";
import { ISignInPayload } from "@/_utils/types";
import AlertModal from "@/components/AlertModal.vue";
import { ExclamationCircleOutlined, CheckCircleFilled } from "@ant-design/icons-vue";
type FormState = Pick<ISignInPayload, "account_email">
interface IAlertModal {
  visible: boolean
  type: "success"|"danger"
  message: string
}
export default defineComponent({
  components: {
    AlertModal,
    ExclamationCircleOutlined,
    CheckCircleFilled
  },
  setup() {
    const { run: requestResetPwd, error } = useRequest(authService.resetPassword, { manual: true })
    const loading = ref<boolean>(false);
    const alertState = reactive<IAlertModal>({
      visible: false,
      message: "",
      type: "danger"
    })
    const formRef = ref();
    const formState: UnwrapRef<FormState> = reactive({
      account_email: ""
    });
    const formRules = {
      account_email: [
        {
          required: true,
          message: "Please input your e-mail address",
          trigger: "blur",
        },
        {
          type: "email",
          message: "Please input an e-mail address format",
          trigger: "blur",
        },
      ]
    };
    
    const handleRequestFinish = () => {
      loading.value = true
      const { account_email } = formState
      requestResetPwd( account_email ).then(
        result => {
          if (error.value) {
            alertState.type = 'danger'
            alertState.message = error.value.message
            //alertState.message = 'คุณไม่สามารถร้องขอรหัสผ่านใหม่ได้ เนื่องจาก บัญชีผู้ใช้งานของคุณไม่มีสิทธิ์ในการเข้าใช้งานระบบ'
            alertState.visible = true;
            return 
          }
          if (!result) {
            alertState.type = 'danger'
            alertState.message = 'ไม่พบอีเมลในระบบ กรุณาตรวจสอบอีกครั้ง';
            alertState.visible = true;
            return
          }
          alertState.type = 'success'
          alertState.message = 'กรุณาตรวจสอบอีเมล เพื่อรีเซ็ทรหัสผ่าน';
          alertState.visible = true;
        }
      ).finally(() => loading.value = false)
    };

    return {
      formRef,
      formRules,
      formState,
      handleRequestFinish,
      loading,
      ...toRefs(alertState)
    };
  }
})
