
import { defineComponent } from "vue";
import ForgotPwdForm from "@/components/forms/ForgotPwdForm.vue";
import CenterCardLayout from "@/components/layouts/CenterCardLayout.vue";

export default defineComponent({
  components: {
    ForgotPwdForm,
    CenterCardLayout
  }
});
